
<template>
  <validation-observer
      ref="resourceSaveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="fileRequireProcessTypeModal"
        ref="fileRequireProcessTypeModal"
        body-class="position-static"
        centered
        size="xl"
        title="Thêm file cho yêu cầu"
        :no-close-on-backdrop="true"
        @hide="onHide"
        @show="onShow"
    >
      <b-row>
        <b-col>
          <p style="color: red; font-style: italic;"> <span class="text-danger">*</span> Chú ý: File bản sao văn bằng, chứng chỉ mà học viên sẽ cung cấp cần ở dạng file pdf </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form
              ref="formRef"
              :style="{height: trHeight}"
          >
            <b-row
                v-for="(item, index) in Files"
                :id="item.id"
                :key="item.id"
                ref="row"
            >
              <b-col md="5">
                <b-form-group label-for="name">
                  <template v-slot:label>
                    Mô tả file yêu cầu <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                      #default="{ errors }"
                      name="Tên file yêu cầu"
                      rules="required"
                  >
                    <b-form-input
                        id="name"
                        v-model="item.name"
                        name="name"
                        placeholder="Nhập mô tả file yêu cầu"
                        :state="getElementState(errors)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="5">
                <b-form-group label-for="fileId">
                  <template v-slot:label>
                    Tải lên file yêu cầu <span class="text-danger">*</span>
                  </template>
                  <b-form-file
                      ref="inputFileRef"
                      :placeholder="Files[index].file"
                      @change="event => uploadFile(event, index)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  lg="2"
                  md="1"
                  class="mb-50"
              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                >
                  <span>Xóa</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeatAgain"
          >
            <feather-icon
                icon="PlusIcon"
                class="mr-25"
            />
            <span>Thêm mới</span>
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="invalid || Files.length === 0"
              variant="primary"
              class="mr-1"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('fileRequireProcessTypeModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          no-wrap
          :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormFile, BFormGroup, BFormInput, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CredentialProcessType',
  directives: {
    Ripple,
  },
  components: {
    BFormFile,
    BModal,
    BOverlay,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  props: {
    idCredentialProcessType: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      Files: [{
        id: 1,
        name: '',
        fileId: null,
        file: 'Chọn file để tải ...',
        fileName: 'Chọn file để tải ...',
        credentialProcessTypeId: this.idCredentialProcessType ? this.idCredentialProcessType.id : null,
      }],
      nextId: 2,
      required,
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      placeholder: 'Chọn file để tải ...',
      mediaIds: [],
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'role/permissionOptions',
      resources: 'role/resourcesOptions',
      certificateProcessTypes: 'certificateProcessType/certificateProcessTypes',
      credentialFilesRequire: 'credentialProcessType/credentialFilesRequire',
    }),
  },
  mounted() {
    this.initTrHeight()
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    await this.readCertificateProcessTypes(this.filterGetAll)
    if (this.credentialFilesRequire.length > 0) {
      this.Files = []
      this.nextId = 0
    } else {
      this.Files = [{
        id: 1,
        name: '',
        fileId: null,
        file: 'Chọn file để tải ...',
        fileName: 'Chọn file để tải ...',
        credentialProcessTypeId: this.idCredentialProcessType.id,
      }]
    }
    this.credentialFilesRequire.forEach(file => {
      this.Files.push({
        id: this.nextId + 1,
        name: file.name,
        fileId: file.fileId,
        file: 'Chọn file để tải ...',
        fileName: file.fileName,
        credentialProcessTypeId: this.idCredentialProcessType.id,
      })
      this.nextId += 1
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      readCertificateProcessTypes: 'certificateProcessType/readCertificateProcessTypes',
      createCredentialProcessTypes: 'credentialProcessType/createCredentialProcessTypes',
      uploadSample: 'certificateBlank/uploadSample',
      readCredentialFileRequire: 'credentialProcessType/readCredentialFileRequire',
      createCredentialFileRequire: 'credentialProcessType/createCredentialFileRequire',
    }),
    async onShow() {
      await this.readCredentialFileRequire(this.idCredentialProcessType)

      if (this.credentialFilesRequire.length > 0) {
        this.Files = []
        this.nextId = 0
      } else {
        this.Files = [{
          id: 1,
          name: '',
          fileId: null,
          fileName: 'Chọn file để tải ...',
          file: 'Chọn file để tải ...',
          credentialProcessTypeId: this.idCredentialProcessType.id,
        }]
      }
      this.credentialFilesRequire.forEach(file => {
        this.Files.push({
          id: this.nextId + 1,
          name: file.name,
          fileId: file.fileId,
          fileName: file.fileName,
          file: file.file,
          credentialProcessTypeId: this.idCredentialProcessType.id,
        })
        this.nextId += 1
      })
    },
    repeatAgain() {
      this.Files.push({
        id: this.nextId += this.nextId,
        name: '',
        fileId: null,
        fileName: 'Chọn file để tải ...',
        file: 'Chọn file để tải ...',
        credentialProcessTypeId: this.idCredentialProcessType.id,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.Files.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.assignPermissionResourceModalRef.scrollHeight)
      })
    },
    onHide() {
      this.Files = [{
        id: 1,
        name: '',
        fileId: null,
        fileName: 'Chọn file để tải ...',
        file: 'Chọn file để tải ...',
      }]
      this.nextId = 2
    },
    async onSave(type = null) {
      const valid = this.$refs
          .resourceSaveFormRef
          .validate()
      if (valid && this.Files.length > 0) {
        this.isLoading = true
        try {
          const response = await this.createCredentialFileRequire({
            id: this.idCredentialProcessType.id,
            params: this.Files,
          })
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('fileRequireProcessTypeModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async uploadFile(event, index) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = event.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      files.forEach(file => formData.append('files', file))

      this.mediaIds = await this.uploadSample(formData)
      if (this.mediaIds.length > 0) {
        this.Files[index].fileId = this.mediaIds[0]
        this.showToast('Upload file thành công', 'CheckIcon', 'success')
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
